import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

/**
 * @prop label        - label of field to show
 * @prop name         - name of select component
 * @prop defaultValue - defaultValue, must correspond to a value in options
 * @prop options      - in the form [{"value": "Value1", "label": "Label1"}, ..]
 * @prop handleChange - callback function when form inputs change
 */
export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectValue:
        (props.defaultValue != null || props.defaultValue === false)
          ? props.defaultValue : '',
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { handleChange } = this.props;
    this.setState({ selectValue: e.target.value });
    handleChange(e);
  }

  render() {
    const {
      name,
      options,
    } = this.props;
    const {
      selectValue,
    } = this.state;

    return (
      <Form.Select
        name={name}
        id={name}
        onChange={this.onChange}
        value={selectValue}
      >
        {
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        }
      </Form.Select>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.bool, PropTypes.number],
  ),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number, PropTypes.bool,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
};
Select.defaultProps = {
  defaultValue: '',
};
