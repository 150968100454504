// Used to validate the userType prop in several components
export const USER_TYPES = Object.freeze({
  ADMINISTRATOR: 'Administrator',
  REGIONAL_MANAGER: 'RegionalManager',
  BILLING_MANAGER: 'BillingManager',
  LOCATION_MANAGER: 'LocationManager',
});

// Mapping from user type to text to display for that user type
export const USER_TYPE_STRINGS = Object.freeze({
  ADMINISTRATOR: 'Admin',
  LOCATION_MANAGER: 'Location Manager',
  BILLING_MANAGER: 'Billing Manager',
  REGIONAL_MANAGER: 'Regional Manager',
});

// Return correctly formatted user type string from User Type
export const userTypeToUserTypeString = (type) => {
  switch (type) {
    case USER_TYPES.ADMINISTRATOR:
      return USER_TYPE_STRINGS.ADMINISTRATOR;
    case USER_TYPES.LOCATION_MANAGER:
      return USER_TYPE_STRINGS.LOCATION_MANAGER;
    case USER_TYPES.BILLING_MANAGER:
      return USER_TYPE_STRINGS.BILLING_MANAGER;
    case USER_TYPES.REGIONAL_MANAGER:
      return USER_TYPE_STRINGS.REGIONAL_MANAGER;
    default:
      throw new Error(`could not find user type: ${type}`);
  }
};

// Used to validate the organizationType prop in several components
export const ORG_TYPES = Object.freeze({
  BUSINESS: 'business',
  RECIPIENT: 'recipient',
});

// Used to style buttons, text, etc.; green for business organizations; blue for others
export const colors = {
  green: '#22B081',
  blue: '#0064B5',
};

export const TIMEZONES = Object.freeze({
  DUBAI: 'Asia/Dubai',
  DENVER: 'America/Denver',
  GMT: 'GMT',
  LOS_ANGELES: 'America/Los_Angeles',
});

export const DAY_NAMES = Object.freeze({
  FULL_WEEK: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  WEEKDAYS: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
});

export const CONTAINER_TYPES = Object.freeze({
  INDIVIDUAL: 'individual-meals',
  SINGLE_SERVING_ITEM: 'single-serving-items',
  CATERING: 'catering-trays',
  CATERING_HALF: 'half-size-catering-trays',
  BOX: 'boxes',
  DELI_QUART: '32oz-deli-containers',
  DELI_PINT: '16oz-deli-containers',
  OTHER: 'other',
});

export const STATES = Object.freeze({
  US: [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
    'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
    'Wisconsin', 'Wyoming',
  ],
  UAE: [
    'Abu Dhabi',
    'Dubai',
    'Sharjah',
    'Ajman',
    'Umm Al Quwain',
    'Ras Al Khaimah',
    'Fujairah',
  ],
});

export const CONTAINER_WEIGHT_UNITS = Object.freeze({
  PER_CONTAINER: 'Weight Per Container',
  TOTAL: 'Whole Entry',
});

export const PLAN_TYPES = Object.freeze({
  STARTER: 'starter',
  A_LA_CARTE: 'a_la_carte',
  MADE_TO_ORDER: 'made_to_order',
  ENTERPRISE: 'enterprise',
});

export const PLANS = Object.freeze({
  [PLAN_TYPES.STARTER]: {
    description: 'Pay $60 per pickup. No commitment.',
    descriptionLong: 'Not ready to commit? Only have surplus a few times a year? We got you. Replate’s Starter would be best in your situation.',
    bullets: [
      '1 Building Limit',
      '1 Floor Limit',
      'No Commitment',
    ],
    pricing: {
      basePickupPrice: 60,
    },
    colors: {
      background: 'light-green',
      text: 'dark-green',
    },
  },
  [PLAN_TYPES.A_LA_CARTE]: {
    description: 'Pay $75 per pickup. No commitment.',
    descriptionLong: 'Surplus is unpredictable - true. Sometimes it’s inevitable. If you know you’ll have extra food throughout the month, but can’t guarantee when, Replate A La Carte fits the bill.',
    bullets: [
      'Impact Metrics',
      'Additional floors & buildings by request',
      'No Commitment',
    ],
    pricing: {
      basePickupPrice: 75,
      additionalFloorPrice: 15,
      additionalBuildingPrice: 30,
    },
    colors: {
      background: 'warning',
      text: 'dark-green',
    },
  },
  [PLAN_TYPES.MADE_TO_ORDER]: {
    description: 'Customizable plan for orgs with complex food rescue needs.',
    descriptionLong: 'You’re committed to hitting sustainability goals and finding long term solutions…Replate Made to Order is for you! This plan is best suited for companies with several pickups a month, larger scale locations, and varying amounts of food.',
    bullets: [
      'Impact Metrics',
      'Access to our Source Reduction Tools',
      'Consulting (with an additional fee)',
      'Additional floors & buildings by request',
      'Yearly contract paid monthly',
    ],
    pricing: {
      basePickupPrice: 65,
      additionalFloorPrice: 10,
      additionalBuildingPrice: 20,
    },
    isSubscription: true,
    colors: {
      background: 'dark-green',
      text: 'white',
    },
  },
  [PLAN_TYPES.ENTERPRISE]: {
    pricing: {},
    colors: {
      background: 'dark-red',
      text: 'white',
    },
  },
});
