import React from 'react';
import PropTypes from 'prop-types';

/**
 * @prop label        - label of field to show
 * @prop name         - name of input component
 * @prop helpText     - help text displayed under input label
 * @prop data         - current input for label
 * @prop editable     - true if fields are editable
 * @prop handleChange - callback function when form inputs change
 * @prop isBusiness   - true if user is business, false if recipient
 */
const EditableInput = (props) => {
  const {
    editable,
    name,
    label,
    helpText,
    data,
    type,
    isBusiness,
    handleChange,
  } = props;

  let inputVal;
  if (editable) {
    inputVal = (
      <input
        id={name}
        name={name}
        type={type || 'text'}
        defaultValue={data}
        className={isBusiness === false ? 'input location-input-recipient' : 'input'}
        onChange={handleChange}
      />
    );
  } else {
    inputVal = data || <i className="help-text">Not Entered</i>;
  }

  let labelVal;
  if (label) {
    labelVal = (
      <div>
        <label
          htmlFor={name}
          className="label label--newline"
        >
          { label }
          :
        </label>
        <p className="help-text">{helpText}</p>
      </div>
    );
  }

  return (
    <>
      <div className="editable-input-label-container">
        { labelVal }
      </div>
      <div className={`editable-input-box-container ${editable}`}>
        { inputVal }
      </div>
    </>
  );
};

EditableInput.propTypes = {
  data: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number],
  ),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isBusiness: PropTypes.bool,
  type: PropTypes.string,
};
EditableInput.defaultProps = {
  data: '',
  helpText: '',
  isBusiness: false,
  type: 'text',
};

export default EditableInput;
