import moment from 'moment-timezone';
import { capitalize } from '../helpers/stringFormatters.helpers';
import { PLAN_TYPES } from './constants';

/**
 * checks whether one time is a given number of minutes before another
 * @param {string} date date on which we're checking times
 * @param {string} startTime
 * @param {string} endTime
 * @param {int} minimumMinuteDifference how many minutes endTime must be after startTime
 * @returns {boolean} whether endTime is minimumMinuteDifference after startTime
 */
// eslint-disable-next-line import/prefer-default-export
export function isStartMinutesBeforeFinish(date, startTime, endTime, minimumMinuteDifference) {
  const startDatetime = moment(`${date} ${startTime}`, 'L LT');
  const endDatetime = moment(`${date} ${endTime}`, 'L LT');
  return (endDatetime.diff(startDatetime, 'minutes') >= minimumMinuteDifference);
}

// this function is copied over from app/assets/javascript/helpers/validation.jsx
export function idForKey() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

/**
 * Takes numbers larger than 1*10^3 and returns a display-friendly string
 * with their abbreviated value (such as 3.3M for 3,300,000)
 * @param {number} num possibly large number to abbreviate for display
 * @returns display-friendly version of that number
 */
export function abbreviateNumber(num) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'B' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(1).replace(rx, '$1') + si[i].symbol;
}

/**
 * @param {string} orgTypeString either 'business' or 'recipient'
 * @param {boolean} capitalizeReturn capitalize the return
 */
export function orgTypeToDisplayString(orgTypeString, capitalizeReturn) {
  let toReturn = orgTypeString;
  if (orgTypeString === 'recipient') {
    toReturn = 'organization';
  }
  if (capitalizeReturn) {
    toReturn = capitalize(toReturn);
  }
  return toReturn;
}

export function planTypeToDisplayString(planType) {
  switch (planType) {
    case PLAN_TYPES.MADE_TO_ORDER:
      return 'Made to Order';
    case PLAN_TYPES.A_LA_CARTE:
      return 'A La Carte';
    default:
      return capitalize(planType);
  }
}

/**
 * Takes a Rails Date string (which doesn't have timezone info)
 * and returns object with strings for month, day, and year.
 * @param {string} railsDateString in the form of "YYYY-MM-DD"
 * @returns object with `month`, `day`, and `year` fields
 */
export function railsDateToStrings(railsDateString) {
  const stringArray = new Date(`${railsDateString}T01:00:00`).toLocaleString(
    'en-gb',
    { day: 'numeric', month: 'short', year: 'numeric' },
  ).split(' '); // initially returns '23 Nov 2001'

  return {
    month: stringArray[1],
    day: stringArray[0],
    year: stringArray[2],
  };
}
