import React, { useState } from 'react';
import { abbreviateNumber } from '../common/utils';

const POUND_OPTIONS = [
  { amount: 100, description: 'Leftovers after an event' },
  { amount: 4000, description: 'Avg monthly donation' },
  { amount: 20000, description: 'Avg yearly donation' },
];

/**
 * Impact Calculator
 * Used on the homepage
 */
export default function HomeImpactCalculator() {
  const [poundsDonated, setPoundsDonated] = useState(4000);

  const calculateImpactStats = () => {
    const meals = Math.ceil(poundsDonated / 1.2);
    const water = Math.ceil(poundsDonated * 273);
    const co2 = Math.ceil(poundsDonated * 2.05);

    return { meals, water, co2 };
  };

  const stats = calculateImpactStats();
  const waterStr = abbreviateNumber(stats.water);
  const mealStr = abbreviateNumber(stats.meals);
  const co2Str = abbreviateNumber(stats.co2);

  const poundItems = POUND_OPTIONS.map((item) => {
    const isSelected = poundsDonated === item.amount;
    return (
      <button
        type="button"
        key={item.amount}
        onClick={() => setPoundsDonated(item.amount)}
        className={`button button--text-black savings-item-button ${isSelected ? 'selected' : ''}`}
      >
        <p className="mb-0">
          {`${item.amount} lbs`}
        </p>
        <p className="subtext">{item.description}</p>
      </button>
    );
  });

  return (
    <section className="savings-container">
      <div className="container grid-lg">
        <h2 className="h1 text-center mb-3 title">What impact can you make?</h2>
        <p className="desc-text desc-text--medium text-center mb-5">Our unique calculator shows the benefits your company can receive by donating food with Replate.</p>

        <div className="row">
          <div className="col-2" />
          <div className="col-sm-8 col-12">
            <h3 className="text-center mb-3">Pounds Donated</h3>
            <div className="savings-item-group">
              {poundItems}
            </div>
          </div>
        </div>
      </div>

      <div className="impact-container container grid-sm">
        <h3 className="h4 text-center mb-4">Your Impact</h3>
        <div className="row">
          <div className="col-4 text-center">
            <div className="stat-icon water-icon" />
            <p className="num">{waterStr}</p>
            <p className="subtext">gal of water saved</p>
          </div>
          <div className="col-4 text-center">
            <div className="stat-icon meal-icon" />
            <p className="num">{mealStr}</p>
            <p className="subtext">meals served</p>
          </div>
          <div className="col-4 text-center">
            <div className="stat-icon co2-icon" />
            <p className="num">{co2Str}</p>
            <p className="subtext">pounds CO2 diverted</p>
          </div>
        </div>
      </div>
    </section>
  );
}

HomeImpactCalculator.propTypes = {};
