/* eslint-disable no-underscore-dangle */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { abbreviateNumber } from '../common/utils';
import DefaultForm from '../formComponents/DefaultForm';

export const MAX_POUNDS = 10000000;
export const MAX_DOLLARS = 999;

/**
 * Savings Calculator
 * Used in Pricing and Home Page
 */
export default class SavingsCalculator extends DefaultForm {
  constructor(props) {
    super(props);

    this.state = {
      poundsDonated: 4000,
      basisValue: 1,
      marketValue: 2,
      costSavings: 0,
    };
  }

  componentDidMount() {
    this.calculateSavings();
  }

  handleChange = (e, maxValue = null) => {
    // First, clamp the input
    const inputValue = e.target.value;

    if (inputValue < 0) {
      e.target.value = 0;
    }

    if (maxValue && inputValue > maxValue) {
      e.target.value = maxValue.toString();
    }

    // If everything passes, handle change
    this._handleChange(e, this.calculateSavings, true);
    return true;
  }

  calculateSavings = () => {
    const { poundsDonated, basisValue, marketValue } = this.state;
    let costSavings;

    if (!poundsDonated || !basisValue) {
      costSavings = ' --';
    } else {
      costSavings = Math.ceil(Math.min(
        basisValue * poundsDonated + (((marketValue - basisValue) / 2) * poundsDonated),
        2 * basisValue * poundsDonated,
      ));
    }

    this.setState({
      costSavings,
    });
  }

  calculateImpactStats = () => {
    const { poundsDonated } = this.state;

    const meals = Math.ceil(poundsDonated / 1.2);
    const water = Math.ceil(poundsDonated * 273);
    const co2 = Math.ceil(poundsDonated * 2.05);

    return { meals, water, co2 };
  }

  render() {
    const stats = this.calculateImpactStats();
    const waterStr = abbreviateNumber(stats.water);
    const mealStr = abbreviateNumber(stats.meals);
    const co2Str = abbreviateNumber(stats.co2);

    const inputContainer = (
      <div className="input-container">
        <h2 className="h1 mb-1">Replate Savings Estimator</h2>
        <p className="desc-text desc-text--medium">
          By donating your extra food through Replate, your company can
          receive tax benefits, support your community, help the environment,
          and reduce waste management costs.
        </p>

        <div className="">
          <fieldset className="savings-input-row">
            <div className="input-row-text">
              <label
                className="label"
                htmlFor="pounds-donated"
              >
                Pounds Donated
              </label>
            </div>

            <div className="input-with-suffix">
              <input
                type="number"
                name="poundsDonated"
                id="pounds-donated"
                min={0}
                maxLength={5}
                placeholder={this.state.poundsDonated}
                onChange={(e) => { this.handleChange(e, MAX_POUNDS); }}
              />
              <span className="suffix">lbs</span>
            </div>

          </fieldset>

          <fieldset className="savings-input-row">
            <div className="input-row-text">
              <div>
                <label
                  className="label label--with-tooltip d-inline"
                  htmlFor="basisValue"
                >
                  Basis Value
                </label>
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      This value is the cost of the food
                      to manufacture per pound.
                    </Tooltip>
                  )}
                >
                  <button
                    type="button"
                    aria-label="basis value info"
                    className="help-tooltip-trigger d-inline-flex"
                  >
                    ?
                  </button>
                </OverlayTrigger>
              </div>
              <p className="help-text">per pound of food</p>
            </div>

            <div className="input-with-suffix">
              <span className="suffix">$</span>
              <input
                name="basisValue"
                type="number"
                id="basisValue"
                min={0}
                placeholder={this.state.basisValue}
                onChange={(e) => { this.handleChange(e, MAX_DOLLARS); }}
              />
            </div>
          </fieldset>

          <fieldset className="savings-input-row">
            <div className="input-row-text">
              <label
                className="label label--with-tooltip d-inline"
                htmlFor="market-value"
              >
                Market Value
              </label>
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                    This value is the amount you intend to charge per pound of food.
                  </Tooltip>
                )}
              >
                <button
                  type="button"
                  className="help-tooltip-trigger d-inline-flex"
                  aria-label="market value info"
                >
                  ?
                </button>
              </OverlayTrigger>
              <p className="help-text">per pound of food</p>
            </div>

            <div className="input-with-suffix">
              <span className="suffix">$</span>
              <input
                type="number"
                name="marketValue"
                id="market-value"
                min={0}
                placeholder={this.state.marketValue}
                onChange={(e) => { this.handleChange(e, MAX_DOLLARS); }}
              />
            </div>
          </fieldset>
        </div>
      </div>
    );

    const resultsContainer = (
      <div className="results-container">
        <div className="tax-savings-container card-bg">
          <div className="form-row" style={{ alignItems: 'center' }}>
            <div>
              <h3 className="h3">Tax Savings:</h3>
              <p className="help-text savings-pounds">
                for
                {' '}
                {this.state.poundsDonated}
                {' '}
                pounds
              </p>
            </div>
            <div className="savings-num--main">{this.state.costSavings}</div>
          </div>
          <p className="disclaimer savings-disclaimer mt-1">
            This is only an estimate and does not take into account earned
            revenue or corporation type. You should not rely on this estimate
            for tax advice. Consult your own advisors before engaging in any
            transaction.
          </p>
        </div>

        <div className="impact-container card-bg">
          <div className="stat-item">
            <div className="stat-icon water-icon" />
            <p className="stat-label">
              Water Saved
            </p>
            <p className="stat-num">
              {waterStr}
              {' '}
              gallons
            </p>
          </div>

          <div className="stat-item">
            <div className="stat-icon meal-icon" />
            <p className="stat-label">
              Meals Served
            </p>
            <p className="stat-num">
              {mealStr}
              {' '}
              meals
            </p>
          </div>

          <div className="stat-item">
            <div className="stat-icon co2-icon" />
            <p className="stat-label">
              CO2 Diverted
            </p>
            <p className="stat-num">
              {co2Str}
              {' '}
              pounds
            </p>
          </div>
        </div>

        <p className="disclaimer results-disclaimer">
          Some organizations may see substantial reductions in waste management
          costs by donating food. Total savings fluctuate significantly and are
          calculated based on city, pounds donated, size of trash bin, and
          frequency of pickups.
        </p>
      </div>
    );

    return (
      <div className="savings-container container grid-lg">
        <div className="row">
          <div className="col-left col-md-6 col-12">
            {inputContainer}
          </div>
          <div className="col-right col-md-6 col-12">
            {resultsContainer}
          </div>
        </div>
      </div>
    );
  }
}

SavingsCalculator.propTypes = {};
