/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @prop label        - label of field to show
 * @prop name         - name of input component
 * @prop data         - current input for label
 * @prop editable     - true if fields are editable
 * @prop handleChange - callback function when form inputs change
 */
export default class EditablePhoneInput extends React.Component {
  checkTextSelected = (input) => input.selectionStart !== input.selectionEnd

  handleInput = (e) => {
    // Validate the input to allow numbers only
    const entry = parseInt(e.key);
    if (!entry && entry !== 0) {
      e.preventDefault();
    }

    // If the user has selected text, resume default behavior
    if (this.checkTextSelected(e.target)) {
      return true;
    }

    const input = e.target.value;
    const rawNum = input.split('-').join('');
    this.filterInput(e, rawNum, this.setInputVal);
  }

  setInputVal = (input, value) => {
    input.value = value;
  }

  filterInput = (e, rawNum, setInputVal) => {
    // prevent the user from inputting more than 10 digits
    let formattedNum;

    if (rawNum.length >= 10) {
      e.preventDefault();
      return;
    }

    if (rawNum.length === 3) {
      formattedNum = `${rawNum}-`;
    } else if (rawNum.length > 3 && rawNum.length < 6) {
      formattedNum = `${rawNum.substring(0, 3)}-${rawNum.substring(3, rawNum.length)}`;
    } else if (rawNum.length >= 6) {
      formattedNum = `${rawNum.substring(0, 3)}-${rawNum.substring(3, 6)}`;
      formattedNum += `-${rawNum.substring(6, rawNum.length)}`;
    } else {
      formattedNum = rawNum;
    }
    setInputVal(e.target, formattedNum);
  }

  render() {
    const {
      editable,
      name,
      label,
      handleChange,
      data,
    } = this.props;

    let inputVal;
    if (editable) {
      inputVal = (
        <input
          type="tel"
          name={name}
          id={name}
          className="input"
          onChange={handleChange}
          onKeyPress={this.handleInput}
          defaultValue={data}
        />
      );
    } else {
      inputVal = data || <i className="help-text">Not Entered</i>;
    }

    let labelVal;
    if (label) {
      labelVal = (
        <label
          htmlFor={name}
          className="label label--newline"
        >
          { label }
          :
        </label>
      );
    }

    return (
      <fieldset className="input-container">
        <div className="editable-input-label-container">
          { labelVal }
        </div>
        <div className="editable-input-box-container">
          { inputVal }
        </div>
      </fieldset>
    );
  }
}

EditablePhoneInput.propTypes = {
  data: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
EditablePhoneInput.defaultProps = {
  data: '',
};
