/**
 * Capitalizes a hyphenated or snake_cased word for display.
 * i.e. turns "grocery-items" into "Grocery Items"
 * also turns "grocery_items" into "Grocery Items"
 * @param {string} value hyphenated or snake_cased multi-word string to capitalized
 * @returns capitalized version of the string
 */
export function hyphenatedToCapitalized(value) {
  return value.split(/-|_/)
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
}

/**
 * Takes an array of strings, and adds commas and conjunctions for display.
 * For example, ['dogs', 'cats', 'birds', 'bananas'] => "dogs, cats, birds, and bananas"
 * @param {array of strings} array
 */
export function stringArrayToDisplayString(stringArray, capitalizeWords = false) {
  const { length } = stringArray;
  let words = stringArray;
  if (capitalizeWords) {
    words = stringArray.map((string) => string.split(' ')
      .map((word) => capitalize(word))
      .join(' '));
  }
  switch (length) {
    case 1:
      return words[0];
    case 2:
      return words.join(' and ');
    default: {
      return `${words.slice(0, -1).join(', ')}, and ${words[length - 1]}`;
    }
  }
}

export function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substr(1);
}
