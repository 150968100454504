/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import RequiredFieldAsterisk from './RequiredAsterisk';

export default function DefaultInput(props) {
  const {
    containerClass,
    inputClass,
    labelClass,
    helpText,
    id,
    label,
    name,
    onChange,
    onKeyDown,
    placeholder,
    inputRef,
    type,
    value,
    required,
    disabled,
    readonly,
  } = props;

  return (
    <fieldset className={`input-container ${containerClass || ''}`}>

      <label
        className={`label ${labelClass || ''}`}
        htmlFor={id}
      >
        {label}
        {required && (
          <>
            {' '}
            <RequiredFieldAsterisk />
          </>
        )}
      </label>

      {helpText && <p className="help-text" style={{ fontSize: '0.9rem' }}>{helpText}</p>}

      <input
        className={`input ${inputClass || ''} ${readonly ? 'form-control-plaintext' : ''}`}
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        id={id}
        ref={inputRef}
        disabled={disabled}
        required={required}
        readOnly={readonly}
      />
    </fieldset>
  );
}

DefaultInput.propTypes = {
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  type: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
};
