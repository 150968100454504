import React from 'react';

/**
 * Displays an asterisk to indicate that a form field is required.
 * Read as "required" to screen readers and assistive technologies.
 *
 * Usage: place _within_ a <label> tag like <label>Name <RequiredFieldAsterisk /></label>
 * Based on https://designsystem.digital.gov/templates/form-templates/name-form/
 */
function RequiredFieldAsterisk() {
  return (
    <abbr
      className="text-decoration-none cursor-text text-danger"
      title="required"
    >
      *
    </abbr>
  );
}

export default RequiredFieldAsterisk;
