import React from 'react';
import PropTypes from 'prop-types';

/**
 * @prop label            - label of field to show
 * @prop first_name_data  - current input for first_name
 * @prop last_name_data   - current input for last_name
 * @prop editable         - true if fields are editable
 * @prop handleChange     - callback function when form inputs change
 * @prop isBusiness       - true if user is business, false if recipient
 */
const EditableFullNameInput = (props) => {
  const {
    editable,
    firstNameData,
    lastNameData,
    label,
    isBusiness,
    handleChange,
  } = props;

  let firstNameInputVal;
  let lastNameInputVal;
  if (editable) {
    firstNameInputVal = (
      <div>
        <input
          name="first_name"
          type="text"
          defaultValue={firstNameData}
          className={isBusiness === false ? 'input location-input-recipient' : 'input'}
          onChange={handleChange}
          placeholder="First Name"
        />
      </div>
    );

    lastNameInputVal = (
      <input
        name="last_name"
        type="text"
        defaultValue={lastNameData}
        className={isBusiness === false ? 'input location-input-recipient' : 'input'}
        onChange={props.handleChange}
        placeholder="Last Name"
      />
    );
  } else {
    firstNameInputVal = firstNameData || <i className="help-text">Not Entered</i>;
    if (lastNameData) {
      lastNameInputVal = (
        <span>
          &nbsp;
          {lastNameData}
        </span>
      );
    }
  }

  let labelVal;
  if (label) {
    labelVal = (
      <div>
        <label
          htmlFor={label}
          className="label label--newline"
        >
          { label }
          :
        </label>
      </div>
    );
  }

  return (
    <fieldset className="input-container">
      <div className="editable-input-label-container">
        { labelVal }
      </div>
      <div className={`editable-input-box-container name first-name ${editable}`}>
        { firstNameInputVal }
      </div>
      <div className={`editable-input-box-container name ${editable}`}>
        { lastNameInputVal }
      </div>
    </fieldset>
  );
};

EditableFullNameInput.propTypes = {
  label: PropTypes.string.isRequired,
  firstNameData: PropTypes.string,
  lastNameData: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isBusiness: PropTypes.bool,
};
EditableFullNameInput.defaultProps = {
  firstNameData: '',
  lastNameData: '',
  isBusiness: false,
};

export default EditableFullNameInput;
