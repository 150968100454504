import React from 'react';
import PropTypes from 'prop-types';
import RequiredFieldAsterisk from './RequiredAsterisk';
import { NumOrStringPropType } from '../helpers/propShapes';

/**
 * @prop label        - label of field to show
 * @prop name         - name & id of input component
 * @prop defaultValue - current input for label
 * @prop editable     - true if fields are editable
 * @prop handleChange - callback function when form inputs change
 */
const EditableDemographicInput = (props) => {
  const {
    label, name, defaultValue, editable, handleChange, required,
  } = props;

  let inputElement;

  if (editable) {
    inputElement = (
      <input
        id={name}
        name={name}
        type="text"
        className="demographic-input"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    );
  } else {
    inputElement = (
      <span className="demographic-input">
        {defaultValue || 'Not Entered'}
      </span>
    );
  }

  return (
    <div className="demographic-question-container">
      { label && (
        <label
          htmlFor={name}
          className="label"
        >
          { label }
          { required && (
            <>
              {' '}
              <RequiredFieldAsterisk />
            </>
          )}
          :
        </label>
      )}
      { inputElement }
    </div>
  );
};

EditableDemographicInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: NumOrStringPropType,
  editable: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

EditableDemographicInput.defaultProps = {
  defaultValue: '',
  required: false,
};

export default EditableDemographicInput;
