/* eslint-disable no-underscore-dangle */

import React from 'react';
import PropTypes from 'prop-types';

import Requester from '../../helpers/requester';
import { validateEmail } from '../../helpers/validationHelpers';

import DefaultInput from '../../formComponents/DefaultInput';
import DefaultForm from '../../formComponents/DefaultForm';

import './NewsletterBanner.scss';

export default class NewsletterBanner extends DefaultForm {
  constructor(props) {
    super(props);
    this.state = {
      subscribed: false,
      hideErrors: true,
      loading: false,
    };
  }

  _handleKeydown = (e) => {
    if (e.keyCode === 13) {
      this._handleSubscribe();
    }
  }

  _handleSubscribe = () => {
    if (validateEmail(this._formFields().email)) {
      this.setState({
        hideErrors: true,
        loading: true,
      });

      const success = () => {
        this.setState({
          subscribed: true,
          loading: false,
        });
      };

      // Requester will always return success because it is a sidekiq job and
      // cannot get a response from the Mailchimp API
      Requester.post(
        '/api/newsletters/subscribe_to_newsletter',
        this._formFields(),
        success,
      );
    } else {
      this.setState({ hideErrors: false });
    }
  }

  render() {
    const {
      subscribed,
    } = this.state;

    let loadingContainer;

    if (this.state.loading) {
      loadingContainer = (
        <div className="loading-container">
          <div className="loading" />
        </div>
      );
    }

    return (
      <div className="newsletter-banner">
        <div className="container">
          <div className="row pt-3 pb-3">
            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center">
              <h2 className="text-center">Get Connected.</h2>
            </div>
            <div className="col-sm-5 col-12">
            {
              subscribed
                ? (
                  <div>
                    <p className="mt-3 sign-up-confirmation">
                      You're all set! Look out for us in your Inbox.
                    </p>
                  </div>
                )
                : (
                  <div>
                    <DefaultInput
                      label="Email Address"
                      id="email-input"
                      name="email"
                      type="email"
                      placeholder="example@email.com"
                      onChange={this._handleChange}
                      onKeyDown={this._handleKeydown}
                      containerClass="mb-3"
                    />

                    <DefaultInput
                      label="First Name"
                      containerClass="mb-3"
                      name="first_name"
                      id="first_name"
                      type="text"
                      onChange={this._handleChange}
                      onKeyDown={this._handleKeydown}
                    />

                    <DefaultInput
                      label="Last Name"
                      containerClass="mb-3"
                      id="last-name-input"
                      name="last_name"
                      type="text"
                      onChange={this._handleChange}
                      onKeyDown={this._handleKeydown}
                    />

                    <div
                      hidden={this.state.hideErrors}
                      className="mt-xs login-error-text"
                    >
                      The email you entered was not valid.
                    </div>
                    <button
                      type="button"
                      className="button ms-xs"
                      onClick={this._handleSubscribe}
                    >
                      Subscribe
                    </button>
                    <div className="agree-tos mt-1">
                      By signing up you agree to our <a href="/terms">Terms of Use and Privacy Policy</a>.
                    </div>
                  </div>
                )
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
