/* eslint-disable class-methods-use-this */
/*
 * Note, this component has been forked.
 * Another version of it lives in app/assets/javascript/requests/request.jsx
 */

import $ from 'jquery';

class Requester {
  initialize(type, route, content = 'application/json') {
    const request = new XMLHttpRequest();
    request.open(type, route);
    request.setRequestHeader('Accept', content);
    request.setRequestHeader('Content-Type', content);
    request.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    return request;
  }

  delete(route, resolve, reject) {
    const request = this.initialize('DELETE', route);
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status === 200 && resolve) {
          resolve(JSON.parse(request.response));
        } else if (request.status === 204 && resolve) {
          resolve();
        } else if (reject) {
          reject(JSON.parse(request.response));
        }
      }
    };
    request.send();
  }

  get(route, resolve, reject) {
    const request = this.initialize('GET', route);
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status === 200 && resolve) {
          resolve(JSON.parse(request.response));
        } else if (reject) {
          reject();
        }
      }
    };
    request.send();
  }

  post(route, params, resolve, reject) {
    const request = this.initialize('POST', route);
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (request.status >= 200 && request.status <= 299 && resolve) {
          if (request.response.length > 0) {
            resolve(JSON.parse(request.response));
          } else {
            resolve();
          }
        } else if (reject) {
          reject(JSON.parse(request.response), request.status);
        }
      }
    };
    request.send(JSON.stringify(params));
  }

  update(route, params, resolve, reject) {
    const request = this.initialize('PATCH', route);
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        if (
          request.status >= 200 && request.status <= 299 && resolve
        ) {
          if (request.response) {
            resolve(JSON.parse(request.response));
          } else {
            resolve();
          }
        } else if (reject) {
          reject(JSON.parse(request.response), request.status);
        }
      }
    };
    request.send(JSON.stringify(params));
  }
}

export default new Requester();
