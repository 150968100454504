import React from 'react';
import PropTypes from 'prop-types';

// import Multiselect from './DefaultMultiSelect';
import ReactSelect from 'react-select';

/**
 * @prop label        - label of field to show
 * @prop name         - name of input component
 * @prop defaultValues - defaultValues, must correspond to values in options
 * @prop options      - in the form [{"value": "Value1", "label": "Label1"}, ..]
 * @prop editable     - true if fields are editable
 * @prop handleChange - callback function when form inputs change
 */
const EditableMultiselect = (props) => {
  const {
    editable, name, options, handleChange, defaultValues, label,
  } = props;
  const defaultOptions = options.filter((option) => defaultValues.includes(option.value));

  let multiselectElement;
  if (editable) {
    multiselectElement = (
      <ReactSelect
        name={name}
        options={options}
        defaultValue={defaultOptions}
        onChange={handleChange}
        placeholder="None selected"
        isMulti
      />
    );
  } else {
    multiselectElement = defaultOptions.map(
      (option) => option.label,
    ).join(', ')
        || <i className="help-text">Not Selected</i>;
  }

  return (
    <fieldset className="input-container">
      { label
        && (
        <div className="editable-input-label-container">
          <label
            htmlFor={label}
            className="label label--newline"
          >
            { label }
            :
          </label>
        </div>
        )}
      <div className="editable-textarea-box-container">
        { multiselectElement }
      </div>
    </fieldset>
  );
};

EditableMultiselect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.bool,
  ])),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number, PropTypes.bool,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  editable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
EditableMultiselect.defaultProps = {
  defaultValues: [],
};

export default EditableMultiselect;
