import React from 'react';
import PropTypes from 'prop-types';

import Select from './DefaultSelect';

/**
 * @prop label        - label of field to show
 * @prop name         - name of input component
 * @prop defaultValue - defaultValue, must correspond to a value in options
 * @prop options      - in the form [{"value": "Value1", "label": "Label1"}, ..]
 * @prop editable     - true if fields are editable
 * @prop handleChange - callback function when form inputs change
 */
export default class EditableSelect extends React.Component {
  getLabelFromValue = (value) => {
    const { options } = this.props;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        return options[i].label;
      }
    }
    return null;
  }

  render() {
    let selectElement;
    const {
      editable, name, options, handleChange, defaultValue, label,
    } = this.props;

    if (editable) {
      selectElement = (
        <Select
          label={label}
          name={name}
          options={options}
          defaultValue={defaultValue}
          handleChange={handleChange}
        />
      );
    } else {
      selectElement = this.getLabelFromValue(defaultValue)
        || <i className="help-text">Not Selected</i>;
    }

    let labelVal;
    if (label) {
      labelVal = (
        <div>
          <label
            htmlFor={name}
            className="label label--newline"
          >
            { label }
            :
          </label>
        </div>
      );
    }

    return (
      <fieldset className="input-container">
        <div className="editable-input-label-container">
          { labelVal }
        </div>
        <div className="editable-textarea-box-container">
          { selectElement }
        </div>
      </fieldset>
    );
  }
}

EditableSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  defaultValue: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.number,
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number, PropTypes.bool,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  editable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};
