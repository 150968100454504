import { isValidPhoneNumber as phoneValidation } from 'libphonenumber-js';

/**
 * Helper functions for form validation and formatting DefaultForms.
 * Moving forward (as of 04/2022), the formatting methods in this file
 * should be disfavored and instead use react-hook-form and
 * bootstrap's form components.
 * But for older components, these are handy methods.
 */

/**
 * Returns true or false depending on if email has valid domain name,
 * address and no illegal characters
 * @param {string} input email to validate
 * @returns boolean
 */
export function validateEmail(input) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(input);
}

/**
 * wrapper around libphonenumber-js.
 */
export function isValidPhoneNumber(phoneNumber) {
  return (!!phoneNumber && phoneValidation(phoneNumber, 'US'));
}

/**
 * Adds formatting css classes to list of error HTML elements
 */
export function addErrorTags(errorList) {
  errorList.forEach((error) => {
    const el = document.getElementsByName(error.inputName)[0];
    el.classList.add('red-border');

    const p = document.createElement('P');
    p.textContent = error.errorString;
    p.setAttribute('class', 'error-msg');
    el.parentNode.appendChild(p);
  });
}
