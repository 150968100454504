import React from 'react';
import ReactDOM from 'react-dom';
import HomeImpactCalculator from '../src/calculators/HomeImpactCalculator';
import SavingsCalculator from '../src/calculators/SavingsCalculator';
import NewsletterBanner from '../src/components/newsletterBanner/NewsletterBanner';

const HOME_IMPACT_CALC_ID = 'home-impact-container';
const SAVINGS_CALC_ID = 'savings-calculator-container';
const NEWSLETTER_BANNER_ID = 'newsletter-banner-container';

/**
 * js pack that adds all necessary savings and impact calculators to a page
 * checks for presence of properly named div, and inserts the calculator there
 */
document.addEventListener('DOMContentLoaded', () => {
  const homeImpactContainer = document.getElementById(HOME_IMPACT_CALC_ID);
  if (homeImpactContainer) {
    ReactDOM.render(
      <HomeImpactCalculator />,
      homeImpactContainer.appendChild(document.createElement('div')),
    );
  }

  const savingsCalcContainer = document.getElementById(SAVINGS_CALC_ID);
  if (savingsCalcContainer) {
    ReactDOM.render(
      <SavingsCalculator />,
      savingsCalcContainer.appendChild(document.createElement('div')),
    );
  }

  const newsletterBannerContainer = document.getElementById(NEWSLETTER_BANNER_ID);
  if (newsletterBannerContainer) {
    ReactDOM.render(
      <NewsletterBanner />,
      newsletterBannerContainer.appendChild(document.createElement('div')),
    );
  }
});
