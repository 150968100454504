import React from 'react';
import PropTypes from 'prop-types';

/**
 * @prop label        - label of field to show
 * @prop name         - name & id of input component
 * @prop defaultValue - default option value to be selected
 * @prop editable     - true if fields are editable
 * @prop handleChange - callback function when form inputs change
 */
export default class EditableDemographicSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  /**
   * TODO: remove this anti-pattern.
   * 'defaultValue' is being set by parent, after component is mounted.
   * Should wait in parent to mount this component until defaultValue
   * is obtained/ensured by the parent component.
   */
  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;
    if (defaultValue && defaultValue !== prevProps.defaultValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: defaultValue });
    }
  }

  onChange = (e) => {
    const { handleChange } = this.props;
    this.setState({ value: e.target.value });
    handleChange(e);
  }

  render() {
    const { label, name, editable } = this.props;
    const { value } = this.state;

    const selectElement = (
      <select
        id={name}
        name={name}
        className="demographic-select"
        onChange={this.onChange}
        disabled={!editable}
        value={value}
      >
        <option value="">No Info</option>
        <option value="0">0%</option>
        <option value="10">10%</option>
        <option value="20">20%</option>
        <option value="30">30%</option>
        <option value="40">40%</option>
        <option value="50">50%</option>
        <option value="60">60%</option>
        <option value="70">70%</option>
        <option value="80">80%</option>
        <option value="90">90%</option>
        <option value="100">100%</option>
      </select>
    );

    let labelVal;
    if (label) {
      labelVal = (
        <span>
          <label
            htmlFor={name}
            className="label"
          >
            { label }
            :
          </label>
        </span>
      );
    }

    return (
      <div className="demographic-question-container">
        { labelVal }
        { selectElement }
      </div>
    );
  }
}

EditableDemographicSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number],
  ),
  editable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
EditableDemographicSelect.defaultProps = {
  defaultValue: '',
  value: '',
};
