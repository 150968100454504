/* eslint-disable */
import React from 'react';
import Requester from '../helpers/requester';

import $ from 'jquery';

import EditableInput from '../formComponents/EditableInput';
import EditablePhoneInput from '../formComponents/EditablePhoneInput';
import EditableTextarea from '../formComponents/EditableTextArea';
import EditableSelect from '../formComponents/EditableSelect';
import EditableMultiselect from '../formComponents/EditableMultiselect';
import Select from '../formComponents/DefaultSelect';
import EditableDemographicSelect from '../formComponents/EditableDemographicSelect';
import EditableDemographicInput from '../formComponents/EditableDemographicInput';
import EditableFullNameInput from '../formComponents/EditableFullNameInput';
/*
 * Note: this is a forked and slightly modified copy of
 * app/assets/javascripts/templates/default_form.jsx. We want files
 * in the Webpacker system to be able to find and use it without affecting
 * any other files dependent on DefaultForm (of which there are many).
 */

/**
 * Component to handle barebones form submissions
 */

export default class DefaultForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

  _handleChange = (e, callback = () => {}, asNumber = false) => {
    const { target } = e;
    const value = asNumber ? Number(target.value) : target.value;
    this.setState({ [target.getAttribute('name')]: value }, callback);
  }

  _handleInputChange = (e, callback = () => {}) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value }, callback);
  }

  _handleMultiselectChange = (name, value) => {
    this.setState({ [name]: value });
  }

  // route is accessed through api constants ie: APIConstants.requests.update returns
  //  /api/requests/${id}
  // params is a dictionary where key = object attribute, value = attribute value
  // resolve is a success function you pass in

  /** TODO: remove this unnecessary function */
  _attemptAction(route, params, success, reject) {
    Requester.post(route, params, success, reject);
  }

  /** TODO: remove this unnecessary function */
  _attemptUpdate(route, params, success, reject) {
    Requester.update(route, params, success, reject);
  }

  _toggleEdit = () => {
    this.setState({ editable : !this.state.editable });
  }

  _formFields() {
    // Necessary because bootstrap-select does not fire onChange events
    const extraFields = { };
    $('.selectpicker').each((index, element) => {
      extraFields[$(element).attr('name')] = $(element).val();
    });
    return $.extend({}, this.state, extraFields);
  }

  _showInput = (label, name, data, helpText = '') => (
    <EditableInput
      label={label}
      helpText={helpText}
      name={name}
      data={data}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showNumberInput = (label, name, data) => (
    <EditableInput
      label={label}
      name={name}
      data={data}
      type="number"
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showLocationSettingsInput = (label, name, data, isBusiness, helpText = '') => (
    <EditableInput
      label={label}
      helpText={helpText}
      name={name}
      data={data}
      isBusiness={isBusiness}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showPhoneInput = (label, name, data) => (
    <EditablePhoneInput
      label={label}
      name={name}
      data={data}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showTextarea = (label, name, data, rows) => (
    <EditableTextarea
      label={label}
      name={name}
      data={data}
      rows={rows}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showSelect = (label, name, defaultValue, options) => (
    <EditableSelect
      label={label}
      name={name}
      defaultValue={defaultValue}
      options={options}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showMultiselect = (label, name, defaultValues, options) => (
    <EditableMultiselect
      label={label}
      name={name}
      defaultValues={defaultValues}
      options={options}
      editable={this.state.editable}
      handleChange={this._handleMultiselectChange}
    />
  )

  _showDefaultSelect = (label, name, defaultValue, options) => (
    <Select
      label={label}
      name={name}
      defaultValue={defaultValue}
      options={options}
      handleChange={this._handleChange}
    />
  )

  _showDemographicSelect = (label, name, defaultValue) => (
    <EditableDemographicSelect
      label={label}
      name={name}
      defaultValue={defaultValue}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showDemographicInput = (label, name, defaultValue) => (
    <EditableDemographicInput
      label={label}
      name={name}
      defaultValue={defaultValue}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )

  _showFullNameInput = (label, firstNameData, lastNameData) => (
    <EditableFullNameInput
      label={label}
      firstNameData={firstNameData}
      lastNameData={lastNameData}
      editable={this.state.editable}
      handleChange={this._handleChange}
    />
  )
}
