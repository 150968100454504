import React from 'react';
import PropTypes from 'prop-types';

/**
 * @prop label        - label of field to show
 * @prop name         - name of input component
 * @prop helpText     - help text displayed under input label
 * @prop data         - current input for label
 * @prop editable     - true if fields are editable
 * @prop handleChange - callback function when form inputs change
 */
const EditableTextarea = (props) => {
  const {
    editable,
    name,
    label,
    helpText,
    data,
    handleChange,
    rows,
  } = props;

  let textAreaVal;
  if (editable) {
    textAreaVal = (
      <textarea
        name={name}
        defaultValue={data}
        className="input"
        onChange={handleChange}
        rows={rows}
      />
    );
  } else {
    textAreaVal = data || <i className="help-text">Not Entered</i>;
  }

  let labelVal;
  if (label) {
    labelVal = (
      <div>
        <label
          htmlFor={label}
          className="label label--newline"
        >
          { label }
          :
        </label>
        <p className="help-text">{helpText}</p>
      </div>
    );
  }

  return (
    <fieldset className="input-container">
      <div className="editable-input-label-container">
        { labelVal }
      </div>
      <div className="editable-textarea-box-container">
        { textAreaVal }
      </div>
    </fieldset>
  );
};

EditableTextarea.propTypes = {
  data: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  helpText: PropTypes.string,
};
EditableTextarea.defaultProps = {
  data: '',
  helpText: '',
};

export default EditableTextarea;
